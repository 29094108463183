<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Manage vehicle
                  <span v-if="route">for {{ route.title }}</span>
                  routes
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \
                  <router-link :to="{ name: 'route' }">
                    Routes
                  </router-link>
                  \
                  vehicle routes
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="manageVehicle()" x-large text>
                  Manage vehicles
                </v-btn>
                <v-btn v-if="checkIsAccessible('vehicle', 'create')" @click="__add()" class="mt-1 btn btn-primary" dark>
                  <i class="fa fa-plus"></i>
                  Add vehicle to routes
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!--                <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                  <v-row>
                    <div class="col-3">
                      <v-text-field label="Title"
                                    type="text"
                                    dense v-on:keyup.enter="__get"
                                    outlined
                                    v-model="search.title"></v-text-field>
                    </div>
                    <div class="col-2">
                      <v-select outlined v-on:keyup.enter="__get" dense :items="active_statuses" item-text="title" item-value="value"
                                v-model="search.active"></v-select>
                    </div>
                    <v-col cols="12" sm="6" md="2">
                      <v-btn
                          @click.prevent="__get"
                          class="btn btn-block btn-primary"
                          style="color: #fff;"
                          :loading="loading"
                      >
                        <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                        Search
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>-->
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column">Route</th>
                    <th class="wrap-column">Bus</th>
                    <th class="wrap-column">Action</th>
                  </tr>
                </thead>
                <draggable v-show="routesVehicles.length > 0" @change="sort" v-model="routesVehicles" class="text-left"
                  tag="tbody">
                  <tr v-for="(routesVehicles, index) of routesVehicles" :key="routesVehicles.title">
                    <td scope="row" class="wrap-column">
                      {{ routesVehicles.routes.title }}
                      <br>
                      <span v-if="routesVehicles && routesVehicles.routes && routesVehicles.routes.start_point"> {{
                        routesVehicles.routes.start_point }} - {{ routesVehicles.routes.end_point }}</span>
                    </td>
                    <td scope="row" class="wrap-column">
                      {{ routesVehicles.vehicles.title }}
                      <br>
                      {{ routesVehicles.vehicles.reg_no }}
                    </td>

                    <td>
                      <b-dropdown size="sm" variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                        right no-flip>
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">

                          <!--                                                                              <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                                                <a href="#" class="navi-link" @click="detail(faq.id)">-->
                          <!--                                                                                        <span class="navi-icon">-->
                          <!--                                                                                          <i class="fas fa-eye"></i>-->
                          <!--                                                                                        </span>-->
                          <!--                                                                                  <span class="navi-text"> View </span>-->
                          <!--                                                                                </a>-->
                          <!--                                                                              </b-dropdown-text>-->
                          <b-dropdown-text tag="div" class="navi-item">
                            <a href="#" class="navi-link" @click="__edit(routesVehicles.id)"
                              v-if="checkIsAccessible('vehicle', 'edit')">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('vehicle', 'delete')">
                            <a href="#" class="navi-link" @click="__delete(routesVehicles.id)">
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="routesVehicles.length == 0">
                  <td colspan="3" class="text-center"><strong>Data not available</strong></td>
                </tr>
              </table>
              <b-pagination v-if="routesVehicles.length > 0" class="pull-right mt-7" @input="__get" v-model="page"
                :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>
          </div>
          <create-and-update ref="routesVehicles" :route="route" @refresh="__get"></create-and-update>

        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from 'vuedraggable'

import VehiclesService from "@/core/services/Transport/Vehicles/VehiclesService";
const vehiclesService = new VehiclesService();
import RoutesService from "@/core/services/Transport/Routes/RoutesService";
const routesService = new RoutesService();
import RoutesVehicleService from "@/core/services/Transport/RoutesVehicle/RoutesVehicleService";
const routesVehicleService = new RoutesVehicleService();

export default {
  name: "routes-vehicles",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    draggable
  },
  computed: {
    routeId() {
      return this.$route.params.routeId;
    }
  },
  data() {
    return {
      routesVehicles: [],
      vehicles: [],
      active_statuses: [
        { title: 'Active', value: "active" },
        { title: 'Inactive', value: "in_active" }
      ],
      route: null,
      page: null,
      perPage: null,
      total: null,
      search: {
        active: 'active'
      },
      videoModel: false,
      video: {},
      dragging: false,
      loading: false
    };
  },
  mounted() {
    if (this.routeId) {
      this.search.route = this.routeId;
      this.getRoutes()
    }
    this.__get();

  },
  methods: {
    __get() {
      this.loading = true;
      routesVehicleService
        .paginate(this.search)
        .then(response => {
          this.routesVehicles = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          // console.log(error);
        });
    },
    getRoutes() {
      routesService.show(this.routeId).then(response => {
        this.route = response.data.route;
      })
    },

    __add() {
      this.$refs["routesVehicles"].showModal();
    },
    __edit(id) {
      this.$refs["routesVehicles"].showModal(id);
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            routesVehicleService
              .delete(id)
              .then(response => {
                this.__get();
              })
              .catch(error => {
                //console.log(error);
              });
          }
        }
      });
    },
    manageVehicle() {
      this.$router.push({
        name: "vehicle",
      });
    },
    sort() {
      routesVehicleService.sort(this.routesVehicles).then((res) => {
        this.$snotify.success("Sorted");
        this.__get()
      }).catch(err => {
        this.$snotify.success("Sorted Error");
      })
    }

  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
